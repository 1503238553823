<template>
  <div class="toolbar">
    <!-- objects custom tooltip -->
    <div
      class="
        objects-list-custom-tooltip
        flex-column
        p-2
        w-auto
        h-auto
        m-0
        shadow
      "
      :class="{ show: showTooltip }"
      @click="showTooltip = !showTooltip"
    >
      <!-- objects status -->
      <div>
        <h6>{{ getTranslationByKey('vehicles.tooltip.object_status.title') }}</h6>
        <div class="info-box d-flex align-items-center smallest mb-2">
          <div class="status-parking mr-2 w-auto pl-3">
            <i class="fas fa-parking"></i>
          </div>
          <p class="mb-0">
            {{ getTranslationByKey('vehicles.tooltip.object_status.stopped') }}
            <span class="text-muted">{{
              getTranslationByKey('vehicles.tooltip.object_status.parking')
            }}</span>
          </p>
        </div>
        <div class="info-box d-flex align-items-center smallest mb-2">
          <div class="status-stopped mr-2 w-auto pl-3">
            <i class="fas fa-car"></i>
          </div>
          <p class="mb-0">
            {{ getTranslationByKey('vehicles.tooltip.object_status.stopped') }}
            <span class="text-muted">{{
              getTranslationByKey('vehicles.tooltip.object_status.engine_on')
            }}</span>
          </p>
        </div>
        <div class="info-box d-flex align-items-center smallest mb-2">
          <div class="status-driving mr-2"><i class="driving"></i></div>
          <p class="mb-0">{{ getTranslationByKey('vehicles.tooltip.object_status.driving') }}</p>
        </div>
        <div class="info-box d-flex align-items-center smallest mb-2">
          <div class="status-hijack mr-2"><i class="hijack"></i></div>
          <p class="mb-0">
            {{ getTranslationByKey('vehicles.tooltip.object_status.moving') }}
            <span class="text-muted">{{
              getTranslationByKey('vehicles.tooltip.object_status.engine_off')
            }}</span>
          </p>
        </div>
        <div class="info-box d-flex align-items-center smallest mb-1">
          <div class="status-offline mr-2"></div>
          <p class="mb-0">{{ getTranslationByKey('vehicles.tooltip.object_status.offline') }}</p>
        </div>
      </div>
      <!-- computer status -->
      <div class="computer-status pt-1">
        <h6>{{ getTranslationByKey('vehicles.tooltip.computer_status.title') }}</h6>
        <div class="info-box d-flex align-items-center smallest mb-2">
          <div class="objects-list-computer-status mr-2">
            <i class="fas fa-tv text-success"></i>
          </div>
          <p class="mb-0">{{ getTranslationByKey('vehicles.tooltip.computer_status.computer_on') }}</p>
        </div>
        <div class="info-box d-flex align-items-center smallest mb-2">
          <div class="objects-list-computer-status mr-2">
            <i class="fas fa-tv pc-off"></i>
          </div>
          <p class="mb-0">{{ getTranslationByKey('vehicles.tooltip.computer_status.computer_off') }}</p>
        </div>
      </div>
      <!-- Bacons and siren information -->
      <div>
        <h6>{{ getTranslationByKey('vehicles.tooltip.bacons_and_siren_info.title') }}</h6>
        <!-- Beacons on -->
        <div class="info-box d-flex align-items-center smallest mb-1">
          <div class="img-wrapper">
            <img src="/img/icons/lights.png" alt="" />
          </div>
          <p class="mb-0">
            {{ getTranslationByKey('vehicles.tooltip.bacons_and_siren_info.beacons_on') }}
          </p>
        </div>
        <!-- Beacons and siren on -->
        <div class="info-box d-flex align-items-center smallest mb-1">
          <div class="img-wrapper">
            <img src="/img/icons/siren.png" alt="" />
          </div>
          <p class="mb-0">
            {{ getTranslationByKey('vehicles.tooltip.bacons_and_siren_info.beacons_and_siren_on') }}
          </p>
        </div>
        <!-- Beacons (escort) on -->
        <div class="info-box d-flex align-items-center smallest mb-1">
          <div class="img-wrapper">
            <img src="/img/icons/lights-escort.png" alt="" />
          </div>
          <p class="mb-0">
            {{ getTranslationByKey('vehicles.tooltip.bacons_and_siren_info.beacons_escort_on') }}
          </p>
        </div>
        <!-- Beacons (escort) and siren on -->
        <div class="info-box d-flex align-items-center smallest mb-1">
          <div class="img-wrapper">
            <img src="/img/icons/siren-escort.png" alt="" />
          </div>
          <p class="mb-0">
            {{ getTranslationByKey('vehicles.tooltip.bacons_and_siren_info.beacons_escort_siren_on') }}
          </p>
        </div>
      </div>
    </div>
    <!-- sort -->
    <button
      class="sort-objects toolbar-btn mr-1"
      @click="sortVehicles"
      v-tooltip.bottom="{
        content: getTranslationByKey('vehicles.tooltip.sort_button.html'),
        theme: 'main',
      }"
    >
      <i class="fas" :class="toggleSortClasses"></i>
    </button>
    <!-- search -->
    <div class="input-control small-input text search-container mr-1">
      <i class="fas fa-search search-icon"></i>
      <input
        type="text"
        :placeholder="transFilterPlaceholder"
        v-model="searchValue"
        @keyup="showFilterResult"
      />
      <i
        class="fa fa-times clear-search-icon"
        v-show="searchValue != ''"
        @click="removeSearchValue"
      ></i>
    </div>
    <!-- filter popup button -->
    <button
      class="toolbar-btn filter-button mr-1"
      :class="{ active: showFilterModal }"
      @click="showFilterModal = !showFilterModal"
      v-tooltip.left="{ content: getTranslationByKey('vehicles.tooltip.filter'), theme: 'main' }"
    >
      <i class="fa fa-filter filter-popup-icon"></i>
    </button>
    <!-- object status info button-->
    <button
      class="toolbar-btn info-button"
      :class="{ active: showTooltip }"
      @click="showTooltip = !showTooltip"
      v-tooltip.left="{ content: getTranslationByKey('vehicles.tooltip.status_desc'), theme: 'main' }"
    >
      <i class="fas fa-info-circle"></i>
    </button>

    <objects-base-modal
      v-if="showFilterModal" 
      :treeOptions="treeOptions"
      @modalClosed="closeModal"
      @onOkButtonPressed="closeModal"
    ></objects-base-modal>
  </div>
  <!--end toolbar-->
</template>

<script>
import ObjectsBaseModal from "../global/ObjectsBaseModal.vue";
import { EventBus } from "../../bus";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ObjectsBaseModal
  },
  data() {
    return {
      searchValue: "",
      sortByNameDesc: true,
      sortByNameAsc: false,
      showTooltip: false,
      showFilterModal: false,

      treeOptions: {
          propertyNames: {
              text: 'title',
              children: 'items'
          },
          urlFilter: {
              filter: 'my',
              with: 'objects,checked'
          },
          filter: {
            emptyText: this.$store.getters.getTranslationByKey('vehicles.objects_filter.nothingfound')
          },
          checkbox: true,
          checkOnSelect: true,
          singleMode: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getTranslationByKey", "getSubscriptionsCount"]),
    toggleSortClasses() {
      return {
        "fa-sort-amount-down-alt": this.sortByNameDesc,
        "fa-sort-amount-down": this.sortByNameAsc,
      };
    },
    transFilterPlaceholder() {
      return this.getTranslationByKey('vehicles.search_over').replace(':objectsCount', this.getSubscriptionsCount) || '';
    }
  },
  mounted() {
    // emitted from objectSidebar
    EventBus.$on("open-object-filter", () => (this.showFilterModal = true));
  },
  methods: {
    ...mapActions(["sort", "filter"]),
    sortVehicles() {
      this.sortByNameDesc = !this.sortByNameDesc;
      this.sortByNameAsc = !this.sortByNameAsc;
      this.sort();
    },
    showFilterResult() {
      this.filter(this.searchValue);

      setTimeout(() => {
        this.$audit({
          action: "object_filter",
          value: { search: this.searchValue },
        });
      }, 500);
    },
    removeSearchValue() {
      this.searchValue = "";
      this.showFilterResult();
    },
    closeModal() {
      return (this.showFilterModal = !this.showFilterModal);
    },
  },
};
</script>
